
import { Options, Vue } from 'vue-class-component';
import { AdminClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Employees extends Vue {

    employees: OM.UserListVM[] = [];

    created() {
        this.init();
    }

    init() {
        AdminClient.getAllUsers()
        .then(x => {
            this.employees = x;
        })
    }

    editEmployee(employee: OM.UserListVM) {
        if(!employee)
            this.$router.push('/employee-edit/');
        else
            this.$router.push('/employee-edit/' + employee.identifier);
    }

}
